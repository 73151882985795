import { datepicker, datetimepicker } from 'flatpickr-prefabs'

window.onload = () => {
    datepickersInit()
    Sys.WebForms.PageRequestManager.getInstance().add_endRequest(EndRequestHandler);
    function EndRequestHandler(sender, args) {
        datepickersInit()
    }
}
const datepickersInit = () => {
    document.querySelectorAll('input.calfield').forEach(function (element) {
        if (element.classList.contains("form-control") && !element.disabled) {
            element.classList.add("datepicker");
        } else if (!element.disabled) {
            element.classList.add("datepicker");
            element.setAttribute("placeholder", "dd/mm/yyyy");
            element.insertAdjacentHTML("afterend", "<span></span>");
        }
    });

    document.querySelectorAll('input.caltimefield').forEach(function (element) {
        if (element.classList.contains("form-control") && !element.disabled) {
            element.classList.add("datetimepicker");
        } else if (!element.disabled) {
            element.classList.add("datetimepicker");
            element.setAttribute("placeholder", "dd/mm/yyyy hh:mm");
            element.style.width = "75%";
            element.insertAdjacentHTML("afterend", "<span></span>");
        }
    });

    datepicker()
    datetimepicker()
}
